import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Card from "../../atoms/card";
import Book from "../../../resources/images/book.png";
import GoodHand from "../../../resources/images/good-hand.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import ClapHandHost from "../../../resources/images/clap-hand-host.png";

import SyncIcon from "../../../resources/images/sync-icon-large.png";
import Megaphone from "../../../resources/images/megaphone.png";
import { useContext, useState } from "react";
import Checkbox from "../../atoms/checkbox";
import CopyField from "../../atoms/copy-field";
import TextArea from "../../atoms/text-area";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import Modal from "../../atoms/modal";
import YouTubePlayer from "../../molecules/youtube-player";

const HostRulesDay = ({ finishChallenge, step, setStep }) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [understand, setUnderstand] = useState(false);
  const [expect, setExpect] = useState(false);
  const [know, setKnow] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [focus, setFocus] = useState(false);
  const [target, setTarget] = useState(false);
  const [demonstrate, setDemonstrate] = useState(false);
  const [skills, setSkills] = useState(false);
  const [motivate, setMotivate] = useState(false);
  const [interest, setInterest] = useState(false);
  const [credibility, setCredibility] = useState(false);
  const [best, setBest] = useState(false);
  const [stop, setStop] = useState(false);
  const [humble, setHumble] = useState(false);
  const [videoSeen, setVideoSeen] = useState(false);
  const [videoSeenTwo, setVideoSeenTwo] = useState(false);
  const [canFinish, setCanFinish] = useState(false);

  const [motivateMyselfText, setMotivateMyselfText] = useState("");
  const [prioritizeAndGetText, setPrioritizeAndGetText] = useState("");

  const [countOptions, setCountOptions] = useState(0);

  const setButtonDisabled = (input) => {
    if (input === 3) {
      setUnderstand(true);
    }
    if (input === 4) {
      setExpect(true);
    }
    if (input === 5) {
      setKnow(true);
    }
    setStep(input);
  };

  const saveChallengeB = () => {
    const data = {
      upliftTeamMembers: personal,
      promoteTrainingSystemAndLearningEnvironment: focus,
      showcasePerformanceWithProvenMethods: demonstrate,
      leverageStrengthsAvoidWeaknesses: skills,
      motivateMyself: motivate,
      motivateMyselfText: motivateMyselfText,
      showActiveConcernForOthers: interest,
      speakAndActWithConviction: credibility,
      giveBestInAllSituations: best,
      prioritizeAndGetResults: stop,
      prioritizeAndGetText: prioritizeAndGetText,
      maintainHumility: humble,
      action: "SAVE_RULES_DAY_CHALLENGE_B",
    };

    axios
      .post(`${API.SAVE_RULES_DAY_CHALLENGE_B.url}/${user?._id}`, data)
      .then((response) => {
        if (response.status === 201) {
          // finishChallenge(step + 1);
          setStep(step + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="day-rules-info">
              <Paragraph align="center" size="s">
                <b>
                  👋🏻 ¡Bienvenido(a) Anfitrión(a) ⚪ a tu Día de <br />
                  Reglas del Juego! 🎮
                </b>
                <br />
                <br />
              </Paragraph>
              <Paragraph
                align="center"
                size="s"
                style={{
                  width: "364px",
                  maxWidth: "364px",
                  marginLeft: "15px",
                }}
              >
                🌤Hoy conocerás:
                <br />
                <br />
                👉🏼 Las indicaciones que debes seguir, para
                <br />
                oder 🛤️ guiar a tus Aspirantes, durante el
                <br />
                proceso de Preselección.
                <br />
                <br />
                👉🏼 El Arte y La Ciencia del
                <br />
                Nivel 2 de Influencia que es <br />
                DESEMPEÑO.
                <br />
                <br />
              </Paragraph>
              <Paragraph align="left" size="s"></Paragraph>
              <YouTubePlayer
                videoId="RRn4MJhoxaw"
                videoSeen={videoSeen}
                setVideoSeen={setVideoSeen}
              />
              <Button
                disabled={!videoSeen}
                styles={{
                  position: "relative",
                  marginTop: "76px",
                }}
                onClick={() => setStep(step + 1)}
              >
                Ya vi el video
              </Button>
            </div>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Paragraph size="s" align="center">
              Lectura sobre el Arte.
              <br />
              <br />
              El 🎨 ”Arte” del Desempeño
              <br />
              (Pensamientos) 💭.
              <br />
              <br />
              Convertirse en una persona productiva, exige cierta manera de
              pensar. Comienza con los supuestos o las maneras de pensar
              correctas. Si no se tienen los pensamientos o los conceptos
              adecuados, su desempeño se verá muy limitado.
              <br />
              <br />
              Las personas con buen desempeño…
            </Paragraph>
            <div className="buttons-container">
              <Button
                onClick={() => setButtonDisabled(3)}
                style="ghost"
                disabled={understand}
              >
                Entienden...
              </Button>
              <Button
                onClick={() => setButtonDisabled(4)}
                style="ghost"
                disabled={expect}
              >
                Tampoco esperan...
              </Button>
              <Button
                onClick={() => setButtonDisabled(5)}
                style="ghost"
                disabled={know}
              >
                Saben...
              </Button>
            </div>
            <Paragraph size="xs" align="center" className="bottom-advice">
              Selecciona cada una de las opciones para poder continuar.
            </Paragraph>
            <Button
              onClick={() => setStep(6)}
              disabled={!(understand && expect && know)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Card type="secondary" outline>
              <div className="card-read">
                <Paragraph align="center" size="s">
                  <b>Entienden...</b>
                  <br />
                  <br />
                  Que los resultados provienen de un esfuerzo personal.
                  <br />
                  <br />
                  Que los campeones no nacen siéndolo.
                  <br />
                  <br />
                  Que nada que realmente valga la pena puede obtenerse con
                  facilidad.
                  <br />
                  <br />
                  Que, muchas veces, el deseo supera al talento, durante su
                  camino de crecimiento y desarrollo.
                </Paragraph>
              </div>
            </Card>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button
              onClick={() => setStep(2)}
              className="host-rules-day-container-button"
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Card type="secondary" outline>
              <div className="card-read">
                <Paragraph align="center" size="s">
                  Tampoco esperan...
                  <br />
                  <br />
                  Triunfar de la noche a la mañana.
                  <br />
                  <br />
                  Ni un trato justo. Buscan la excelencia, concentrándose sólo
                  en lo que pueden controlar.
                </Paragraph>
              </div>
            </Card>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button
              onClick={() => setStep(2)}
              className="host-rules-day-container-button"
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Card type="secondary" outline>
              <div className="card-read">
                <Paragraph align="center" size="s">
                  Saben...
                  <br />
                  <br />
                  Que siempre habrá críticos negativos y aprenden a ignorarlos
                  mientras se mantienen enfocadas en su visión y su propósito.
                  <br />
                  <br />
                  Que siempre existirá un rival que se opondrá a sus logros y
                  utilizan la confrontación para mejorar.
                  <br />
                  <br />
                  Que la oportunidad siempre llega para quienes se preparan.
                  <br />
                  <br />
                  Que la actitud vence a las circunstancias.
                  <br />
                  <br />
                  Que la excelencia siempre puede ser mejor y nunca están
                  satisfechas.
                  <br />
                  <br />
                  Que creer es poder.
                </Paragraph>
              </div>
            </Card>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button
              onClick={() => setStep(2)}
              className="host-rules-day-container-button"
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Paragraph size="s" align="center">
              Ahora que sabes que el Arte del Desempeño inicia con la manera de
              pensar correcta…
            </Paragraph>
            <Paragraph size="s" align="center">
              <b>
                En tu caso 👉🏼 ¿Cuál de los siguientes supuestos o pensamientos
                de las personas productivas, consideras el más importante a
                interiorizar y aplicar?
              </b>
            </Paragraph>
            <Checkbox
              name="personal"
              label="“Mis resultados provienen de mi esfuerzo personal”."
              checked={personal}
              onChange={(e) => {
                setPersonal(!personal);
                setTarget(false);
                setFocus(false);
              }}
            />
            <Checkbox
              name="focus"
              label="“Mi éxito es mi decisión y me enfoco solamente en lo que puedo controlar”."
              checked={focus}
              onChange={(e) => {
                setFocus(!focus);
                setTarget(false);
                setPersonal(false);
              }}
            />
            <Checkbox
              name="target"
              label="“Creo en que lo puedo lograr y en que sí es posible”."
              checked={target}
              onChange={(e) => {
                setTarget(!target);
                setFocus(false);
                setPersonal(false);
              }}
            />
            <Button
              onClick={() => {
                if (personal) {
                  setStep(7);
                } else if (focus) {
                  setStep(8);
                } else if (target) {
                  setStep(9);
                }
              }}
              disabled={!personal && !focus && !target}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Card
              type="secondary"
              outline
              className="host-rules-day-container-card"
            >
              <div className="card-read">
                <Paragraph align="left" size="s">
                  ✒️ “Las personas productivas entienden que los campeones no
                  nacen siéndolo. Que se vuelven así por medio del compromiso
                  que surge a partir de la decisión de ser magníficos. En la
                  vida, cada tanto tenemos la oportunidad de hacer algo
                  grandioso. Cuando llega ese momento, solo importan dos cosas:
                  estar preparado para poder aprovechar el momento y tener la
                  valentía de hacer tu mejor esfuerzo”. ”Todos quieren ganar.
                  Pero muy pocos están dispuestos a prepararse para ello. No hay
                  mucho que puedas controlar en esta vida. Pero lo que sí puedes
                  controlar es qué tanto te esfuerzas.” Dijo la entrenadora de
                  baloncesto Pat Summit. .
                </Paragraph>
              </div>
            </Card>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button
              onClick={() => setStep(10)}
              className="host-rules-day-container-button"
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={8}
            visibility={step === 8 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Card
              type="secondary"
              outline
              className="host-rules-day-container-card"
            >
              <div className="card-read">
                <Paragraph align="left" size="s">
                  ✒️ Las personas productivas entienden que el éxito siempre se
                  encuentra una vez vencidos los inconvenientes, que nada que
                  vale la pena se obtiene fácilmente, ni esperan que las cosas
                  pasen de la noche a la mañana.” Saben que siempre habrá
                  críticos, que existirán adversarios y que “no faltarán las
                  personas que nos harán mal, que nos engañarán, nos mentirán,
                  nos robarán o simplemente herirán a los demás.” Sin embargo,
                  toman todo esto en consideración, aprenden a ignorar a los
                  críticos mientras se mantienen enfocados en su visión y su
                  propósito; y “buscan la excelencia de todos modos,
                  concentrándose solo en lo que pueden controlar”.
                </Paragraph>
              </div>
            </Card>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button
              onClick={() => setStep(10)}
              className="host-rules-day-container-button"
            >
              Continuar
            </Button>
          </Step>

          <Step
            order={9}
            visibility={step === 9 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Card
              type="secondary"
              outline
              className="host-rules-day-container-card"
            >
              <div className="card-read">
                <Paragraph align="left" size="s">
                  ✒️ Las personas productivas entienden que el éxito siempre se
                  encuentra una vez vencidos los inconvenientes, que nada que
                  vale la pena se obtiene fácilmente, ni esperan que las cosas
                  pasen de la noche a la mañana.” Saben que siempre habrá
                  críticos, que existirán adversarios y que “no faltarán las
                  personas que nos harán mal, que nos engañarán, nos mentirán,
                  nos robarán o simplemente herirán a los demás.” Sin embargo,
                  toman todo esto en consideración, aprenden a ignorar a los
                  críticos mientras se mantienen enfocados en su visión y su
                  propósito; y “buscan la excelencia de todos modos,
                  concentrándose solo en lo que pueden controlar”.
                </Paragraph>
              </div>
            </Card>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button
              onClick={() => setStep(10)}
              className="host-rules-day-container-button"
            >
              Continuar
            </Button>
          </Step>
          <Step order={10} visibility={step === 10 ? "visible" : "hidden"}>
            <div className="day-rules-info">
              <Paragraph align="center" size="s">
                <b>Pensamientos Anfitrión(a) ⚪ </b>
      
              </Paragraph>
              <Paragraph
                align="center"
                size="s"
                style={{
                  width: "364px",
                  maxWidth: "364px",
                  marginLeft: "15px",
                }}
              >
                🤖 Ahora que ya conoces sobre la importancia
                <br />
                de la forma de pensar de un líder con buen
                <br />
                desempeño y el 🎨  Arte 💭que debes practicar
                <br />
                para lograrlo…
                <br />
              </Paragraph>
              <Paragraph
                align="center"
                size="s"
                style={{
                  width: "364px",
                  maxWidth: "364px",
                  marginLeft: "15px",
                }}
              >
                📲 Envía a tu Coordinador(a) 🔴, una 🗣️ <br />
                Nota de voz respondiendo, según sea el
                <br />
                caso, a alguna de las siguientes preguntas:
              </Paragraph>
              <br />
              <Card
                type="secondary"
                style={{
                  margin: "10px",
                }}
              >
                <Paragraph
                  size={"s"}
                  align={"center"}
                  style={{
                    maxWidth: "364px",
                    width: "318px",
                  }}
                >
                  <b>
                    Si ya lograste la Meta 1 🎯👉🏼_tienes 6<br />
                    Aspirantes agendados para el EPA.🎟️…
                  </b>
                  <br />
                  <br />
                  ¿Cuáles fueron los pensamientos 💭 que
                  <br />
                  sostuviste para lograrlo?
                </Paragraph>
              </Card>
              <Card
                type="secondary"
                style={{
                  margin: "10px",
                }}
              >
                <Paragraph
                  size="s"
                  align="center"
                  style={{
                    maxWidth: "364px",
                    width: "318px",
                  }}
                >
                  <b>
                    Si aún no tienes tus 6 Aspirantes
                    <br /> confirmados para el EPA 🎟️…{" "}
                  </b>
                  <br />
                  <br />
                  ¿Cuál(es) pensamiento(s) 💭 eliges sostener para lograrlo?
                </Paragraph>
              </Card>

              <Button
                styles={{
                  position: "relative",
                  marginTop: "76px",
                }}
                onClick={() => setStep(step + 1)}
              >
                Ya envié la nota de voz a mi Coordinador
              </Button>
            </div>
          </Step>
          <Step order={11} visibility={step === 11 ? "visible" : "hidden"}>
            <div className="day-rules-info">
              <Paragraph align="center" size="s">
                <b>Anfitrión(a) ⚪</b>
             
              </Paragraph>
              <Paragraph
                align="center"
                size="s"
                style={{
                  width: "364px",
                  maxWidth: "364px",
                  marginLeft: "15px",
                }}
              >
                👍🏼 Ganas: Medalla Comodín:🃏 <br />
                👉🏼 Si tu 🗣️  Nota de voz dura <br />
                entre entre 0:50 y 1:10.
                <br />
              </Paragraph>
              <Paragraph
                align="center"
                size="s"
                style={{
                  width: "364px",
                  maxWidth: "364px",
                  marginLeft: "15px",
                }}
              >
                ⚠️ Tu Coordinador(a) 🔴 es tu Guía, así que tu
                <br />
                honestidad y espontaneidad serán muy útiles
                <br />
                para que pueda apoyarte como lo necesites y<br />
                cuando lo necesites.
              </Paragraph>
            
              <Paragraph
                align="center"
                size="s"
                style={{
                  width: "364px",
                  maxWidth: "364px",
                  marginLeft: "15px",
                }}
              >
                <b>Continúa con el ▶️ Video de Reglas del Juego</b>
              </Paragraph>
              <YouTubePlayer
                videoId="-URijcIW63o"
                videoSeen={videoSeenTwo}
                setVideoSeen={setVideoSeenTwo}
              />
              <Button
                disabled={!videoSeenTwo}
                styles={{
                  position: "relative",
                  marginTop: "76px",
                }}
                onClick={() => setStep(step + 1)}
              >
                Ya vi el video
              </Button>
            </div>
          </Step>
          <Step
            order={12}
            visibility={step === 12 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Paragraph size="s" align="center">
              <b>¡Genail!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Has completado el <b>Desafío A.</b>
            </Paragraph>
            <img src={ClapHandHost} alt="Good Hand" className="good-hand" />
            <Card type="secondary" >
           
                <Paragraph align="center" size="s"  style={{
                    maxWidth: "364px",
                    width: "318px",
                  }}>
                Ahora pasarás  a  la lectura de LA CIENCIA<br/>
                DEL DESEMPEÑO del Desafío B del<b> Día de<br/> Reglas del Juego. </b>
                <br/>
                <br/>
                👋🏻 Ya nos vemos.
                </Paragraph>
            
           
            </Card>
            <Button  styles={{
                  position: "relative",
                  marginTop: "76px",
                }} onClick={() => finishChallenge(null)} >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Card type="secondary" outline>
              <div className="card-read">
                <Paragraph align="center" size="s">
                  Lectura sobre la Ciencia
                  <br />
                  <br />
                  La 🔎 ”Ciencia” del Desempeño (Acciones)
                  <br />
                  <br />
                  Después de comprender a fondo los supuestos que los líderes en
                  desarrollo necesitan para convertirse en personas productivas,
                  es preciso ahondar en las acciones de la productividad, o el
                  lado “científico” de lo que significa convertirse en una
                  persona productiva. Las personas productivas, deben ser
                  capaces de reconocer que tendrán más logros si trabajan como
                  parte integral de un equipo que si lo hacen solos. Como lo
                  dijo Ray Kroc.fundador de McDonald´s Corporation: “Ninguno de
                  nosotros es tan importante como todos nosotros.” Y, al mismo
                  tiempo que cumplen su rol en la estructura integral del
                  equipo, las personas con buen desempeño: Edifican el
                  liderazgo, utilizando las palabras y el respeto para “elevar a
                  los demás”. Promueven un sistema de capacitación y ambiente de
                  aprendizaje. Demuestran sus capacidades, siguiendo y dominando
                  los métodos ya establecidos y comprobados. Construyen sobre
                  sus fortalezas y no alimentan sus debilidades. Se motivan a si
                  mismas. Se interesan y preocupan activamente por los demás.
                  Aumentan su credibilidad demostrando convicción mediante sus
                  palabras y acciones. Dan lo mejor de sí en todas las
                  situaciones. Se enfocan en las prioridades y obtienen
                  resultados. No se detienen en los halagos que reciben.
                  Conservan su HUMILDAD sabiendo que no son mejores que los
                  demás y se cuidan de los riesgos del ego y de la arrogancia.
                  Que creer es poder.
                </Paragraph>
              </div>
            </Card>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left">
                Tomado del libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Paragraph size="s" align="center">
              Entendiendo que cumples un rol importante en la estructura
              integral de un equipo…
            </Paragraph>
            <Paragraph size="s" align="center" className="paragraph-card">
              ¿Cuál(es) de las siguientes acciones relacionadas con la Ciencia
              🔎 del Desempeño te comprometes a realizar para lograr el día de
              hoy la Meta 1 (Agendar a 6 Aspirantes para el EPA ya programado)?
            </Paragraph>
            <Paragraph size="xs" align="center">
              Selecciona entre 1 (mínimo) y 3 (máximo), como una forma de
              establecer tu compromiso contigo mismo(a).
            </Paragraph>
            <Checkbox
              name="getUp"
              label="“Elevar a los miembros de mi equipo”, utilizando las palabras y el respeto."
              checked={personal}
              onChange={(e) => {
                setPersonal(!personal);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Checkbox
              name="promote"
              label="Promover el sistema de capacitación y el ambiente de aprendizaje."
              checked={focus}
              onChange={(e) => {
                setFocus(!focus);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Checkbox
              name="demonstrate"
              label="Demostrar mi propio desempeño, siguiendo y dominando los métodos ya establecidos y comprobados."
              checked={demonstrate}
              onChange={(e) => {
                setDemonstrate(!demonstrate);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Checkbox
              name="skills"
              label="Aprovechar mis fortalezas y no alimentar mis debilidades."
              checked={skills}
              onChange={(e) => {
                setSkills(!skills);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Checkbox
              name="motivate"
              label="Motivarme a mí mismo(a)."
              checked={motivate}
              onChange={(e) => {
                setMotivate(!motivate);
                setMotivateMyselfText("");
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <TextArea
              placeholder="¿Cómo?"
              onChange={(e) => setMotivateMyselfText(e.target.value)}
              value={motivateMyselfText}
              disabled={!motivate}
              required
            />
            <Checkbox
              name="interest"
              label="Mostrar interés y preocupación activa por los demás."
              checked={interest}
              onChange={(e) => {
                setInterest(!interest);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Checkbox
              name="credibility"
              label="Hablar y actuar con convicción."
              checked={credibility}
              onChange={(e) => {
                setCredibility(!credibility);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Checkbox
              name="best"
              label="Dar lo mejor en todas las situaciones."
              checked={best}
              onChange={(e) => {
                setBest(!best);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Checkbox
              name="stop"
              label="Priorizar y obtener resultados."
              checked={stop}
              onChange={(e) => {
                setStop(!stop);
                setPrioritizeAndGetText("");
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <TextArea
              placeholder="¿A qué le diste prioridad?"
              onChange={(e) => setPrioritizeAndGetText(e.target.value)}
              value={prioritizeAndGetText}
              disabled={!stop}
            />
            <Checkbox
              name="humble"
              label="Mantener la HUMILDAD para no creerme mejor que los demás."
              checked={humble}
              onChange={(e) => {
                setHumble(!humble);
                console.log(humble, countOptions);
                if (e.target.checked) {
                  setCountOptions(countOptions + 1);
                } else {
                  setCountOptions(countOptions - 1);
                }
              }}
            />
            <Button onClick={saveChallengeB} disabled={countOptions !== 3}>
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Vas super!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Te queda poco para completar el <b>Desafío B.</b>
            </Paragraph>
            <img src={ClapHand} alt="Clap Hand" className="good-hand" />
            <Card type="secondary" outline>
              <div className="epa-schedule-card">
                <Paragraph align="center" size="s">
                  Copia la clave que deberás pegar y enviar a TWOBOT para
                  continuar:
                </Paragraph>
                <div className="epa-schedule-card__key">
                  ABC Convocatoria Plan B
                </div>
                <CopyField
                  value="ABC Convocatoria Plan B"
                  setStep={() => {
                    setCanFinish(true);
                  }}
                />
                <Paragraph align="center" size="xs" padding={16}>
                  Podrás copiar la clave en cualquier momento desde la{" "}
                  <span className="icon-info"></span> información del{" "}
                  <b>Desafío B</b> en
                  <b>Mi Progreso</b>
                </Paragraph>
              </div>
            </Card>
            <Button onClick={() => setStep(step + 1)} disabled={!canFinish}>
              Finalizar
            </Button>
          </Step>
          <Modal
            isOpen={step === 4}
            className="confirmation-register-modal large"
          >
            <div className="confirmation-register-container">
              <div className="light">
                <img src={Megaphone} alt="Warning" />
              </div>
              <Paragraph align="center" size="s">
                ¡Hey!
                <br />
                <br />
                Aún te falta cumplir la <b>Meta 1:</b> Agendar 6 Aspirantes para
                el EPA.
                <br />
                <br />
                Tu trabajo no ha terminado, aplica el
                <b>ABC Convocatoria</b> con los Validadores que te hacen falta.
              </Paragraph>
              <Button size="small" onClick={() => finishChallenge(null)}>
                ¡Vale!
              </Button>
            </div>
          </Modal>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="host-rules-day-container"
          >
            <Paragraph size="s" align="center">
              <b>¡Estás iniciando el Desafío C!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Hora ideal para realizar este desafío:
            </Paragraph>
            <img
              src={SyncIcon}
              alt="Sync Icon"
              className="sync-icon"
              width={136}
              height={136}
            />
            <span className="hour">9:30 pm</span>
            <Paragraph size="s" align="center" className="large-paragraph">
              Aunque puedes realizarlo antes, implementar este horario permite
              que:
              <br />
              <br />
              👉🏼 Los dos Anfitriones completen la Meta 1.
              <br />
              <br />
              👉🏼  Reforcemos una de las estrategias del juego ¡A esta hora se
              aplica la Sincronización con todo el equipo ACDC al cierre de cada
              Misión Diaria!
            </Paragraph>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Vas super!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img src={GoodHand} alt="Good Hand" className="good-hand" />
            <Card type="secondary" outline>
              <div className="epa-schedule-card">
                <Paragraph align="center" size="s">
                  Copia la clave que deberás pegar y enviar a TWOBOT para
                  continuar:
                </Paragraph>
                <div className="epa-schedule-card__key">
                  Entrenamiento ABC Preselección
                </div>
                <CopyField
                  value="Entrenamiento ABC Preselección"
                  setStep={() => {
                    setCanFinish(true);
                  }}
                />
                <Paragraph align="center" size="xs" padding={16}>
                  Podrás copiar la clave en cualquier momento desde la{" "}
                  <span className="icon-info"></span> información del{" "}
                  <b>Desafío B</b> en
                  <b>Mi Progreso</b>
                </Paragraph>
              </div>
            </Card>
            <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default HostRulesDay;
